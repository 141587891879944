/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  ModalButtons,
  Error,
  LoadingAnimation,
  GenerateInput,
  IntegrationTab,
  ProposalsTab,
  Success,
} from '..';
import { updateInstrumentStoreValue } from '../../store/actions';
import {
  chevronDownDark,
  // link
} from '../../assets';
import {
  CheckNegative,
  DisplayNegative,
  // GenerateValue,
} from '../../utils/assetmap/calculate';
import { CapitalizeFirstLetter, FormatCurrency } from '../../utils';
import {
  ExpandableItem,
  ExpandedDetails,
  ModalExpandHeadingContainer,
  ExpandHeadingLabel,
  ExpandIcon,
  LabelText,
  ModalSidebarContainer,
  ToolbarContainer,
  SidebarMessageContainer,
  InputsContainer,
  InputsHeading,
  IntegrationsSidebarContainer,
  ProposalsSidebarContainer,
} from '../../styles/library/sidebarStyles';
import {
  LoadingOverlay,
  LoadingOverlayContainer,
  ModalLoadingOverlayContainer,
} from '../../styles/library/modalStyles';
import { ErrorThemes, SuccessThemes } from '../../styles/themes';
import {
  assetMapColors,
  colors,
  fonts,
  messageColors,
} from '../../styles/variables';

const InstrumentSidebar = ({
  isEdit,
  item,
  isLoading,
  household,
  currency,
  modalTabOptions,
  setActiveTab,
  essentialInputs,
  detailInputs,
  notesInput,
  proposalContent,
  integrationContent,
  buttonContent,
  showProposalMode,
  error,
  errorTitle,
}) => {
  const dispatch = useDispatch();
  const { updatedInstrument, instrumentAction } = useSelector((state) => ({
    updatedInstrument: state.instruments.updatedInstrument,
    instrumentAction: state.instruments.instrumentAction,
  }));
  const tabOptions = [
    {
      value: 'essentials',
      label: 'Essentials',
      isExpanded: false,
    },
    {
      value: 'details',
      label: 'Details',
      isExpanded: false,
    },
    {
      value: 'notes',
      label: 'Notes',
      isExpanded: false,
    },
  ];
  const [inputGroups, setInputGroups] = useState(tabOptions);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (updatedInstrument && instrumentAction) {
      if (instrumentAction !== 'read') {
        let typeDisplay = 'Financial';
        let actionDisplay = 'Update';
        if (updatedInstrument?.instrument_type) {
          typeDisplay = updatedInstrument?.instrument_type;
          if (updatedInstrument?.instrument_type === 'income') {
            typeDisplay = 'Cash Flow';
          }
        }
        if (instrumentAction) {
          actionDisplay = instrumentAction;
        }

        typeDisplay = CapitalizeFirstLetter(typeDisplay);
        actionDisplay = CapitalizeFirstLetter(actionDisplay);
        setSuccessMessage(`${typeDisplay} ${actionDisplay}d`);
        dispatch(updateInstrumentStoreValue('instrumentAction', null));
        setTimeout(() => {
          setSuccessMessage('');
          dispatch(updateInstrumentStoreValue('updatedInstrument', false));
        }, 2500);
      }
    }
  }, [updatedInstrument && instrumentAction]);

  useEffect(() => {
    if (modalTabOptions) {
      const updatedGroups = modalTabOptions.map((option) => {
        const isExpanded = localStorage.getItem(option.value);
        option.isExpanded = isExpanded === 'true';
        if (option.integration) {
          option.isExpanded = false;
        }
        return option;
      });
      setInputGroups(updatedGroups);
    }
  }, [modalTabOptions]);

  const generateBorderColor = (instrument) => {
    let borderColor = '';
    if (instrument) {
      if (instrument.instrument_type === 'liability') {
        borderColor = assetMapColors.liability;
      }
      if (instrument.instrument_type === 'insurance') {
        borderColor = assetMapColors.insurance;
      }
      if (instrument.instrument_type === 'income') {
        borderColor = assetMapColors.cashFlow;
      }
      if (instrument.instrument_type === 'asset') {
        if (instrument.category === 'retirement') {
          borderColor = assetMapColors.retirement;
        }
        if (instrument.category === 'nonretirement') {
          borderColor = assetMapColors.nonRetirement;
        }
        if (instrument.category === 'residence') {
          borderColor = assetMapColors.realEstate;
        }
      }
      if (instrument.status === 'stencil') {
        borderColor = assetMapColors.stencil;
      }
    }
    return borderColor;
  };

  const generateBgColor = (instrument) => {
    let borderColor = '';
    if (instrument) {
      if (instrument.instrument_type === 'liability') {
        borderColor = `rgb(252, 247, 242)`;
      }
      if (instrument.instrument_type === 'insurance') {
        borderColor = `rgb(241, 244, 248)`;
      }
      if (instrument.instrument_type === 'income') {
        borderColor = `rgb(245, 250, 251)`;
      }
      if (instrument.instrument_type === 'asset') {
        if (instrument.category === 'retirement') {
          borderColor = `rgb(252, 244, 247)`;
        }
        if (instrument.category === 'nonretirement') {
          borderColor = `rgb(248, 251, 243)`;
        }
        if (instrument.category === 'residence') {
          borderColor = `rgb(248, 249, 251)`;
        }
      }
      if (instrument.status === 'stencil') {
        borderColor = `rgb(241, 241, 241)`;
      }
    }

    return borderColor;
  };

  const onExpandGroup = (section) => {
    if (section.integration && !section.isExpanded) {
      setActiveTab(section.value);
    }
    setInputGroups(
      inputGroups.map((group) => {
        if (group.value === section.value) {
          localStorage.setItem(group.value, !group.isExpanded);
          group.isExpanded = !group.isExpanded;
        } else if (group.integration && section.integration) {
          group.isExpanded = false;
        }
        return group;
      })
    );
  };

  const generateBottomPadding = () => {
    const hasError = error && error !== '';
    const isNew = !isEdit;
    let height = 0;
    const errorHeight = 38;
    if (isNew) {
      height = 25;
    }
    if (hasError) {
      height += errorHeight;
    }
    return `${height}px`;
  };

  return (
    <ModalSidebarContainer $paddingBottom={generateBottomPadding()}>
      {isEdit && item ? (
        <>
          <InstrumentHeadingContainer
            $borderColor={generateBorderColor(item)}
            $bgColor={generateBgColor(item)}
            $isHidden={item.is_hidden}
          >
            <InstrumentName>{item?.name}</InstrumentName>
            <InstrumentSource>
              {item?.source}
              {/* {!item?.is_managed && '*'} */}
            </InstrumentSource>
            {!item?.is_amount_hidden && (
              <InstrumentAmount
                style={{
                  color: CheckNegative(item) ? messageColors.error : null,
                }}
              >
                {/* {item?.is_linked && (
                  <LinkedImage
                    src={link}
                    alt="linked"
                    data-image="item-linked"
                  />
                )} */}
                {DisplayNegative(item)
                  ? `(${FormatCurrency(item?.amount, currency)})`
                  : FormatCurrency(item?.amount, currency)}
                {/* {item?.amount_period_display && item?.amount_period_display} */}
                {/* {item?.instrument_type === 'income' && '/yr'} */}
                {/* {item.show_cash_value && !item.ignore_cash_value && ' cv'} */}
                {/* <LightText>
                  {item.split_percent === 0
                    ? `(${item.split_percent}%)`
                    : item.split_percent &&
                      item.split_percent !== 100 &&
                      `(${item.split_percent}%)`}
                  {item?.is_rollup_parent &&
                    item?.percent !== 100 &&
                    `(${item.percent}%)`}
                </LightText> */}
                {/* {item.is_future_value &&
                  item.instrument_type !== 'insurance' &&
                  ' fv'} */}
                {/* <LightText>
                  {item.show_joint && !item.ignore_joint && '(JT)'}
                </LightText> */}
              </InstrumentAmount>
            )}
          </InstrumentHeadingContainer>
          <InstrumentSectionsContainer>
            <ModalLoadingOverlayContainer>
              {(isLoading || buttonContent.showLoading) && (
                <LoadingOverlayContainer>
                  <LoadingOverlay>
                    <LoadingAnimation />
                  </LoadingOverlay>
                </LoadingOverlayContainer>
              )}
              {inputGroups.map((group) => {
                return (
                  !group.isHidden && (
                    <ExpandableItem key={group.value}>
                      <ModalExpandHeadingContainer
                        onClick={() => onExpandGroup(group)}
                        $isExpanded={group.isExpanded}
                      >
                        <ExpandHeadingLabel>
                          <LabelText>{group.label}</LabelText>
                        </ExpandHeadingLabel>
                        <ExpandIcon $isExpanded={group.isExpanded}>
                          <img src={chevronDownDark} alt="down" />
                        </ExpandIcon>
                      </ModalExpandHeadingContainer>
                      {group.isExpanded && (
                        <ExpandedDetails $padding="0">
                          {group.value === 'essentials' ? (
                            <InputsContainer>
                              {essentialInputs.map((input, index) => {
                                return GenerateInput(input, index, currency);
                              })}
                            </InputsContainer>
                          ) : group.value === 'details' ? (
                            <InputsContainer>
                              {detailInputs.map((input, index) => {
                                return GenerateInput(input, index, currency);
                              })}
                            </InputsContainer>
                          ) : group.value === 'notes' ? (
                            <InputsContainer $padding={'20px 15px 50px 15px'}>
                              {GenerateInput(notesInput, 0)}
                            </InputsContainer>
                          ) : group.value === 'proposals' ? (
                            <ProposalsSidebarContainer>
                              <ProposalsTab
                                isSidebar={true}
                                isConsumer={false}
                                proposalContent={proposalContent}
                                hasError={error && error !== ''}
                              />
                            </ProposalsSidebarContainer>
                          ) : (
                            <IntegrationsSidebarContainer>
                              <IntegrationTab
                                isSidebar={true}
                                content={integrationContent?.content}
                                loading={integrationContent?.loading}
                                integration={integrationContent?.integration}
                                instrument={integrationContent?.instrument}
                                household={household}
                                role={integrationContent?.role}
                                onClick={integrationContent?.onClick}
                                onClickLoading={
                                  integrationContent?.onClickLoading
                                }
                                error={integrationContent?.error}
                                type={integrationContent?.type}
                                lastHouseholdSync={
                                  integrationContent?.lastHouseholdSync
                                }
                              />
                            </IntegrationsSidebarContainer>
                          )}
                        </ExpandedDetails>
                      )}
                    </ExpandableItem>
                  )
                );
              })}
            </ModalLoadingOverlayContainer>
          </InstrumentSectionsContainer>
        </>
      ) : (
        <ExpandedDetails $padding="0">
          <InputsContainer $padding="15px 15px">
            <InputsHeading>Essentials</InputsHeading>
            {essentialInputs.map((input, index) => {
              return GenerateInput(input, index, currency);
            })}
          </InputsContainer>
          <InputsContainer
            $padding="15px 15px"
            $background={colors.hoverLighterGrey}
            $showBorder={true}
          >
            <InputsHeading>Details</InputsHeading>
            {detailInputs.map((input, index) => {
              return GenerateInput(input, index, currency);
            })}
          </InputsContainer>
          <InputsContainer $padding="15px 15px 0px 15px">
            <InputsHeading>Notes</InputsHeading>
            {GenerateInput(notesInput, 0)}
          </InputsContainer>
        </ExpandedDetails>
      )}
      {error && error !== '' && (
        <SidebarMessageContainer>
          <Error
            errorMessage={error}
            title={errorTitle}
            theme={ErrorThemes.sidebar}
          />
        </SidebarMessageContainer>
      )}
      {successMessage && successMessage !== '' && (
        <SidebarMessageContainer>
          <Success
            successMessage={successMessage}
            theme={SuccessThemes.sidebar}
          />
        </SidebarMessageContainer>
      )}
      <ToolbarContainer>
        <ModalButtons
          isNew={!isEdit}
          showCopy={isEdit && !showProposalMode}
          isHidden={buttonContent.isHidden}
          justCopied={buttonContent.justCopied}
          updateHidden={showProposalMode ? null : buttonContent.updateHidden}
          copyFunction={buttonContent.copyFunction}
          deleteFunction={buttonContent.deleteFunction}
          saveFunction={buttonContent.saveFunction}
          showLoading={buttonContent.showLoading}
          loadingText={buttonContent.loadingText}
          showSync={buttonContent.showSync}
          syncFunction={buttonContent.syncFunction}
          isSyncing={buttonContent.isSyncing}
          isSidebar={true}
        />
      </ToolbarContainer>
    </ModalSidebarContainer>
  );
};

const InstrumentSectionsContainer = styled.div``;

const InstrumentName = styled.p`
  font-size: 16px;
  font-weight: ${fonts.semiBold};
  line-height: 22px;
`;

const InstrumentSource = styled.p`
  font-size: 15px;
  line-height: 24px;
`;

const InstrumentAmount = styled.p`
  font-size: 18px;
  font-weight: ${fonts.semiBold};
  line-height: 22px;
`;

const InstrumentHeadingContainer = styled.div`
  padding: 15px 20px;
  word-break: break-word;
  ${'' /* border-top: 5px solid; */}
  border-bottom: 5px solid;
  border-color: ${(props) => props.$borderColor};
  background: ${(props) => props.$bgColor};
  ${
    '' /* box-shadow: ${(props) => ` 0px 4px 10px -4px ${props.$borderColor}40`}; */
  }
  box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.1);
  z-index: 5;
  position: sticky;
  top: 0;
  background-image: ${(props) =>
    props.$isHidden
      ? `url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%238a888e' fill-opacity='0.2' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E")`
      : null};
  ${InstrumentName}, ${InstrumentSource}, ${InstrumentAmount} {
    opacity: ${(props) => (props.$isHidden ? 0.5 : null)};
  }
`;

// const LinkedImage = styled.img`
//   width: 10px;
//   max-height: 10px;
//   margin-right: 3px;
//   opacity: 0.8;
// `;

// const LightText = styled.span`
//   font-weight: ${fonts.light};
//   margin-left: 2px;
//   font-size: 14px;
// `;

export default InstrumentSidebar;
