/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { RelationshipDisplayContainer } from '../../components';
import {
  GetStates,
  SetDefaultItem,
  CheckHasError,
  GenerateError,
  states,
  modalTabs,
  integrationOptions,
  MemberOption,
  HtmlToString,
  DetectEnterKeyPress,
  UseTransformOwnersList,
  MatchState,
  MatchCountry,
  TransformOwners,
  DateFormat,
  SendTrackingEvent,
  FilterMembersByContext,
  clientModalTabs,
  UseGenerateMembersList,
} from '../../utils';
import {
  createMember,
  updateMember,
  toggleShowDeleteModal,
  getMemberIntegrationInfo,
  unlinkSalesforceMember,
  updateMemberStoreValue,
  clearMemberInfo,
  createProposalItem,
  updateProposalItem,
} from '../../store/actions';
import { ButtonThemes } from '../../styles/themes';

const EntityContainer = ({
  isModal = true,
  viewOnly = false,
  hide,
  isNew,
  isExcluded,
  householdId,
  showProposalMode,
  proposalId,
  defaultTab,
  invisible,
  isConsumer,
  itemDetails,
}) => {
  const dispatch = useDispatch();
  const {
    integrations,
    preferences,
    showExcludedMembers,
    member,
    loadedMemberFromAPI,
    clearedMemberInfo,
    memberIntegration,
    memberIntegrationError,
    memberError,
    entityCategories,
    countries,
    currentHousehold,
    updatedMember,
    hasEntityRollup,
    members,
    invocationId,
  } = useSelector((state) => ({
    integrations: state.user?.user?.integrations,
    preferences: state.user.user.preferences,
    showExcludedMembers: state.user.showExcludedMembers,
    member: state.members.member,
    loadedMemberFromAPI: state.members.loadedMemberFromAPI,
    clearedMemberInfo: state.members.clearedMemberInfo,
    memberIntegration: state.members.memberIntegration,
    memberIntegrationError: state.members.memberIntegrationError,
    memberError: state.members.memberError,
    entityCategories: state.configs.entityCategories,
    countries: state.configs.countries,
    currentHousehold: state.households.currentHousehold,
    updatedMember: state.members.updatedMember,
    hasEntityRollup: state.user.hasEntityRollup,
    members: state.households.currentHousehold.householdmembers,
    invocationId: state.configs.invocationId,
  }));
  const [activeTab, setActiveTab] = useState('essentials');
  const [modalTabOptions, setModalTabOptions] = useState(
    isConsumer ? clientModalTabs : modalTabs
  );
  const defaultEntity = {
    name: '',
    owners: [],
    entity_rollup: false,
    date_of_birth_estimated: false,
    is_active: true,
    address: '',
    address2: '',
    city: '',
    zip: '',
    notes: '',
  };
  const [currentEntity, setCurrentEntity] = useState(defaultEntity);
  const [loadedEntity, setLoadedEntity] = useState(false);
  const [selectedRole, setSelectedRole] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [stateDisabled, setStateDisabled] = useState(false);
  const [entityRoles, setEntityRoles] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [entityMembers, setEntityMembers] = useState([]);
  const [hasSelectedMembers, setHasSelectedMembers] = useState(false);
  const [loadedMembers, setLoadedMembers] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [disabledFields, setDisabledFields] = useState([]);
  const [modalError, setModalError] = useState('');
  const [modalErrors, setModalErrors] = useState();
  const [selectedIntegration, setSelectedIntegration] = useState();
  const [integrationContent, setIntegrationContent] = useState();
  const [integrationError, setIntegrationError] = useState();
  const [loadingIntegrationContent, setLoadingIntegrationContent] =
    useState(true);
  const [isUnlinkingEntity, setIsUnlinkingEntity] = useState(false);
  const [entityDisplay, setEntityDisplay] = useState('Entity');
  const [showRemoveWarning, setShowRemoveWarning] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(clearMemberInfo());
    };
  }, []);

  useEffect(() => {
    if (clearedMemberInfo) {
      dispatch(updateMemberStoreValue('clearedMemberInfo', false));
    }
  }, [clearedMemberInfo]);

  useEffect(() => {
    if (isExcluded) {
      setCurrentEntity({ ...currentEntity, is_active: false });
      setEntityDisplay('Other Entity');
    } else {
      setEntityDisplay('Entity');
    }
  }, [isExcluded]);

  useEffect(() => {
    if (updatedMember) {
      setButtonLoading(false);
    }
  }, [updatedMember]);

  useEffect(() => {
    if (entityCategories && entityCategories.length !== 0) {
      setEntityRoles(entityCategories);
    }
  }, [entityCategories]);

  useEffect(() => {
    if (countries && countries.length !== 0) {
      setCountryList(
        countries.map((country) => {
          country.label = country.name;
          country.value = country.code;
          return country;
        })
      );
    }
  }, [countries]);

  useEffect(() => {
    if (isNew) {
      setLoadedEntity(true);
      SetDefaultItem(countries, currentHousehold.country, setSelectedCountry);
      if (membersList && membersList.length !== 0) {
        setCurrentEntity({
          ...defaultEntity,
          owners: [...FilterMembersByContext(membersList, 'instrument_owner')],
        });
      }
    }
  }, [isNew, membersList]);

  useEffect(() => {
    if (activeTab) {
      if (!modalTabs.map((tab) => tab.value).includes(activeTab)) {
        let matchedIntegration = integrationOptions.find(
          (int) => int.id === activeTab
        );
        if (matchedIntegration) {
          setSelectedIntegration(matchedIntegration);
        }
        setLoadingIntegrationContent(true);
        dispatch(
          getMemberIntegrationInfo(currentHousehold.id, member.id, activeTab)
        );
      } else {
        setIntegrationContent();
      }
    }
  }, [activeTab]);

  useEffect(() => {
    if (memberIntegration) {
      setLoadingIntegrationContent(false);
      setIntegrationContent(memberIntegration);
      setIntegrationError();
    }
  }, [memberIntegration]);

  useEffect(() => {
    if (memberIntegrationError) {
      setLoadingIntegrationContent(false);
      setLoadedEntity(true);
      setIntegrationError(memberIntegrationError);
    }
  }, [memberIntegrationError]);

  useEffect(() => {
    if (memberError) {
      setButtonLoading(false);
      setLoadedEntity(true);
      if (memberError?.data) {
        if (memberError.data?.message) {
          return setModalError(memberError.data?.message);
        } else if (memberError?.data?.owners) {
          setModalError(memberError?.data?.owners);
        }
        setModalErrors(memberError?.data);
        const length = Object.keys(memberError?.data).length;
        if (length === 1) {
          return setModalError(
            memberError.data[Object.keys(memberError.data)[0]]
          );
        }
      } else {
        setModalError('Please fix errors and try saving again.');
      }
    }
  }, [memberError]);

  useEffect(() => {
    if (selectedCountry && selectedCountry.value !== 'US') {
      setSelectedState(null);
      setStateDisabled(true);
    } else {
      setStateDisabled(false);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedRole) {
      setModalError('');
      setModalErrors();
    }
  }, [selectedRole]);

  useEffect(() => {
    if (!isNew && loadedMembers) {
      if (member && member.hasOwnProperty('id')) {
        if (member?.is_proposal) {
          setModalTabOptions([
            ...(isConsumer ? clientModalTabs : modalTabs),
            { label: 'Drafts', value: 'proposals' },
          ]);
        } else {
          setModalTabOptions(isConsumer ? clientModalTabs : modalTabs);
        }
        if (
          member.hasOwnProperty('integrations') &&
          !showProposalMode &&
          !isConsumer
        ) {
          if (member.integrations.hasOwnProperty('available')) {
            const householdIntegrations = member.integrations.available;
            //Need to check if user has these enabled, if yes then set it as tab
            let integrationTabs = [];
            if (householdIntegrations.length !== 0 && integrations) {
              integrationTabs = householdIntegrations.reduce((acc, curr) => {
                if (integrations[curr]) {
                  let integrationName = curr;
                  let matchedIntegration = integrationOptions.find(
                    (int) => int.id === curr
                  );
                  if (matchedIntegration) {
                    integrationName = matchedIntegration.name;
                    setSelectedIntegration(matchedIntegration);
                  }
                  return [
                    ...acc,
                    {
                      label: integrationName,
                      value: curr,
                      integration: true,
                      icon: matchedIntegration?.icon
                        ? matchedIntegration?.icon
                        : false,
                    },
                  ];
                }
                return acc;
              }, []);
            }
            setModalTabOptions([...modalTabs, ...integrationTabs]);
          }
          if (
            member.integrations.hasOwnProperty('read_only_fields') &&
            member.integrations.read_only_fields.length !== 0
          ) {
            setDisabledFields(member.integrations.read_only_fields);
          } else {
            setDisabledFields([]);
          }
        }
        setCurrentEntity({
          id: member.id,
          uuid: member?.uuid,
          role: matchRole(member.category),
          name: HtmlToString(member.name),
          owners: TransformOwners(
            FilterMembersByContext(membersList, 'entity_owner'),
            member.owners,
            true
          ),
          entity_rollup: member?.entity_rollup,
          date_of_birth: member.date_of_birth,
          date_of_birth_estimated: member.date_of_birth_estimated,
          is_active: member.is_active,
          address: member.address,
          address2: member.address2,
          city: member.city,
          state: MatchState(member.state, states, setSelectedState),
          country: MatchCountry(
            member.country,
            countryList,
            setSelectedCountry
          ),
          zip: member.zip,
          notes: member.notes,
        });
        setLoadedEntity(loadedMemberFromAPI);
        setButtonLoading(false);
        if (defaultTab) {
          setActiveTab(defaultTab);
        }
      }
    }
  }, [member, integrations, clearedMemberInfo, loadedMembers]);

  useEffect(() => {
    return () => {
      setMembersList(
        membersList.map((member) => {
          member.isSelected = false;
          return member;
        })
      );
    };
  }, []);

  UseGenerateMembersList(
    currentHousehold,
    setMembersList,
    true,
    setLoadedMembers,
    member
  );
  UseTransformOwnersList(
    currentEntity,
    isNew,
    setEntityMembers,
    setHasSelectedMembers,
    setModalError,
    setModalErrors
  );

  const matchRole = (role) => {
    if (role === 'other') {
      role = 'corpother';
    }
    let matched = entityRoles.find((mem) => mem.value === role);
    if (matched) {
      return setSelectedRole(matched);
    }
  };

  const saveEntity = () => {
    let hasError = false;
    let errorMessage = 'Please fix errors and try again.';
    let errorsObj = {};
    if (selectedRole === undefined) {
      errorsObj['role'] = ['Please select a Category.'];
      errorMessage = 'Please select a Category.';
      hasError = true;
    }
    if (currentEntity.name === '') {
      errorsObj['last_name'] = ['Name is required.'];
      errorMessage = 'Name is required.';
      hasError = true;
    }
    const owners = currentEntity.owners.filter((member) => member.isSelected);
    let mappedOwners = [];
    if (currentEntity.entity_rollup) {
      mappedOwners = owners.map((owner) => {
        const ratio = parseFloat(owner.ratio);
        return {
          id: owner.id,
          ratio: owner.ratio === '' ? 0 : ratio.toFixed(3),
          relation: 'owner',
        };
      });
      if (mappedOwners.length === 0) {
        setModalErrors({ owners: [] });
        errorsObj['owners'] = ['Please select a Linked Member.'];
        errorMessage =
          'Must have at least one linked member for Entity Rollup.';
        hasError = true;
      }
    } else {
      if (owners && owners.length !== 0) {
        const length = owners.length;
        mappedOwners = owners.map((owner) => {
          return { id: owner.id, ratio: Math.round(100 / length) };
        });
      }
    }
    //BACKEND HANDLES ERROR HANDLING
    // const ownerRatioTotal = mappedOwners.reduce((acc, curr) => {
    //   return acc + parseInt(curr.ratio);
    // }, 0);
    // if (ownerRatioTotal > 100) {
    //   setModalErrors({
    //     owners: ['Percentage cannot be greater than 100.'],
    //   });
    //   return setModalError('Percentage cannot be greater than 100.');
    // }
    // if (ownerRatioTotal <= 0) {
    //   setModalErrors({
    //     owners: ['Percentage cannot be less than or equal to 0.'],
    //   });
    //   return setModalError('Percentage cannot be less than or equal to 0.');
    // }

    if (hasError) {
      const errorCount = Object.keys(errorsObj)?.length;
      if (errorCount > 1) {
        errorMessage = 'Please fix errors and try again.';
      }
      setModalErrors(errorsObj);
      return setModalError(errorMessage);
    }

    let state = '';
    if (selectedState && selectedState.value) {
      state = selectedState.value;
    }
    let country = '';
    if (selectedCountry && selectedCountry.value) {
      country = selectedCountry.value;
    }

    let dob = null;
    if (currentEntity.date_of_birth) {
      dob = DateFormat(currentEntity.date_of_birth, 'YYYY-MM-DD');
    }
    let entityObject = {
      category: selectedRole.value,
      name: currentEntity.name,
      entity_rollup: currentEntity.entity_rollup,
      member_type: 'entity',
      date_of_birth: dob,
      date_of_birth_estimated: currentEntity.date_of_birth_estimated,
      is_active: currentEntity.is_active,
      address: currentEntity.address,
      address2: currentEntity.address2,
      city: currentEntity.city,
      owners: mappedOwners,
      state,
      country,
      zip: currentEntity.zip,
      notes: currentEntity.notes,
    };
    setButtonLoading(true);
    if (isNew) {
      return dispatch(
        showProposalMode
          ? createProposalItem(
              currentHousehold?.uuid,
              proposalId,
              entityObject,
              'householdmember',
              'Entity',
              isConsumer
            )
          : createMember(householdId, entityObject, isConsumer, !isModal)
      );
    } else {
      return dispatch(
        showProposalMode
          ? updateProposalItem(
              currentHousehold?.uuid,
              proposalId,
              entityObject,
              member?.uuid,
              'householdmember',
              'Entity',
              member?.proposal_action_data?.id,
              isConsumer
            )
          : updateMember(
              householdId,
              member.id,
              entityObject,
              isConsumer,
              !isModal
            )
      );
    }
  };

  const saveOnEnter = () => {
    if (buttonLoading || activeTab === 'notes') {
      return;
    }
    return saveEntity();
  };

  const openDeleteMember = () => {
    dispatch(
      toggleShowDeleteModal(
        true,
        isExcluded ? 'otherEntity' : 'entity',
        member.name,
        member.id,
        null,
        member,
        !isModal
      )
    );
  };

  const updateCurrentEntity = (e, isSelect = false, fieldName = null) => {
    setModalError('');
    setModalErrors();
    if (isSelect) {
      setCurrentEntity({
        ...currentEntity,
        [fieldName]: e,
      });
    } else {
      setCurrentEntity({
        ...currentEntity,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  const updateLinkedMembers = (e) => {
    setCurrentEntity({
      ...currentEntity,
      owners: currentEntity.owners.map((member) => {
        if (e.id === member.id) {
          member.isSelected = !member.isSelected;
        }
        return member;
      }),
    });
  };

  const ratioChange = (e, member) => {
    setModalError('');
    setModalErrors();
    member.ratio = e.currentTarget.value;
    setCurrentEntity({ ...currentEntity });
  };

  const unlinkMember = () => {
    setIsUnlinkingEntity(true);
    dispatch(
      unlinkSalesforceMember(householdId, currentEntity.id, true, false)
    );
  };

  const isDisabled = (input) => {
    if (viewOnly) {
      return true;
    } else if (!isNew && disabledFields.length !== 0) {
      return disabledFields.includes(input);
    }
    return false;
  };

  const isActiveOnChange = (currentEntity) => {
    const showWarning = currentEntity.is_active && !isExcluded;
    if (showWarning) {
      SendTrackingEvent(
        invocationId,
        'click',
        'exclude_member_from_household',
        'household_members',
        {
          isConsumer: isConsumer,
        }
      );
      return setShowRemoveWarning(true);
    }
    setCurrentEntity({
      ...currentEntity,
      is_active: !currentEntity.is_active,
    });
  };

  const essentialInputs = [
    {
      type: 'select',
      label: 'Category',
      name: 'role',
      required: true,
      placeholder: 'Category',
      value: selectedRole,
      options: entityRoles,
      width: '100%',
      isVisible: true,
      disabled: isDisabled('category'),
      onChange: (e) => setSelectedRole(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'role'),
      errorMessage: GenerateError(modalErrors, 'role'),
    },
    {
      type: 'text',
      label: 'Name',
      maxLength: '80',
      name: 'name',
      width: '100%',
      required: true,
      placeholder: 'Name',
      value: HtmlToString(currentEntity.name),
      isVisible: true,
      disabled: isDisabled('last_name'),
      onChange: (e) => updateCurrentEntity(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'last_name'),
      errorMessage: GenerateError(modalErrors, 'last_name'),
    },
    {
      type: 'select',
      label: 'Linked Members',
      customSelect: true,
      customComponent: MemberOption,
      name: 'owners',
      ratioKey:
        currentEntity.entity_rollup && currentEntity.is_active
          ? 'ratio'
          : false,
      required: false,
      placeholder: 'Linked members',
      value: { label: 'Select Members', value: '' },
      options: currentEntity.owners,
      memberType: 'owner',
      showSelected: hasSelectedMembers,
      selectedOptions: entityMembers,
      width: '100%',
      isVisible: true,
      hasSelected: true,
      disabled: isDisabled('linkedMembers'),
      optionOnChange: ratioChange,
      onChange: (e) => updateLinkedMembers(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'owners'),
      errorMessage: GenerateError(modalErrors, 'owners'),
    },
    {
      type: 'checkbox',
      label: 'Include in My Household',
      name: 'is_active',
      required: false,
      width: isModal ? '49%' : '100%',
      margin: '0 0 10px -3px',
      value: currentEntity.is_active,
      isVisible: showExcludedMembers && !showProposalMode,
      disabled: isDisabled('is_active'),
      onChange: () => {
        isActiveOnChange(currentEntity);
      },
      // hasError: layoutNameFieldError,
    },
    {
      type: 'checkbox',
      label: 'Assign Entity Ownership',
      name: 'entity_rollup',
      width: isModal ? '49%' : '100%',
      margin: '0 0 10px -3px',
      required: false,
      value: currentEntity.entity_rollup,
      isVisible: hasEntityRollup && !isExcluded,
      onChange: () =>
        setCurrentEntity({
          ...currentEntity,
          entity_rollup: !currentEntity.entity_rollup,
        }),
    },
  ];

  const detailInputs = [
    {
      type: 'date',
      label: 'Incorporation Date',
      name: 'date_of_birth',
      width: '100%',
      required: false,
      placeholder: preferences?.date_format.toLowerCase(),
      dateFormat: preferences?.date_format,
      value: currentEntity.date_of_birth,
      isVisible: true,
      disabled: isDisabled('incorporation_date'),
      onChange: (e) => updateCurrentEntity(e, true, 'date_of_birth'),
      hasError: CheckHasError(modalErrors, 'date_of_birth'),
      errorMessage: GenerateError(modalErrors, 'date_of_birth'),
    },
    {
      type: 'checkbox',
      label: 'Incorporation Date Is Estimated',
      name: 'date_of_birth_estimated',
      required: false,
      value: currentEntity.date_of_birth_estimated,
      isVisible: true,
      disabled: isDisabled('incorporation_date_estimated'),
      onChange: () =>
        setCurrentEntity({
          ...currentEntity,
          date_of_birth_estimated: !currentEntity.date_of_birth_estimated,
        }),
      // hasError: layoutNameFieldError,
    },
    {
      type: 'text',
      label: 'Address',
      name: 'address',
      width: '100%',
      required: false,
      placeholder: 'Address',
      value: currentEntity.address,
      isVisible: true,
      disabled: isDisabled('address'),
      onChange: (e) => updateCurrentEntity(e),
      hasError: CheckHasError(modalErrors, 'address'),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      errorMessage: GenerateError(modalErrors, 'address'),
    },
    {
      type: 'text',
      name: 'address2',
      width: '100%',
      required: false,
      placeholder: 'Address Additional',
      value: currentEntity.address2,
      isVisible: true,
      disabled: isDisabled('address2'),
      onChange: (e) => updateCurrentEntity(e),
      hasError: CheckHasError(modalErrors, 'address2'),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      errorMessage: GenerateError(modalErrors, 'address2'),
    },
    {
      type: 'text',
      label: 'City',
      maxLength: '45',
      name: 'city',
      width: isModal ? '40%' : '100%',
      required: false,
      placeholder: 'City',
      value: currentEntity.city,
      isVisible: true,
      disabled: isDisabled('city'),
      onChange: (e) => updateCurrentEntity(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'city'),
      errorMessage: GenerateError(modalErrors, 'city'),
    },
    {
      type: 'select',
      label: 'State',
      name: 'state',
      width: isModal ? '30%' : '58%',
      required: false,
      placeholder: 'State',
      options: GetStates(states),
      value: selectedState,
      isVisible: true,
      onChange: (e) => setSelectedState(e),
      onEnter: saveOnEnter,
      disabled: isDisabled('state') || stateDisabled,
      hasError: CheckHasError(modalErrors, 'state'),
      errorMessage: GenerateError(modalErrors, 'state'),
    },
    {
      type: 'text',
      label: 'Zip Code',
      name: 'zip',
      width: isModal ? '25%' : '38%',
      // maxLength: '5',
      required: false,
      placeholder: 'Zip Code',
      value: currentEntity.zip,
      isVisible: true,
      disabled: isDisabled('zip'),
      onChange: (e) => updateCurrentEntity(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'zip'),
      errorMessage: GenerateError(modalErrors, 'zip'),
    },
    {
      type: 'select',
      label: 'Country',
      name: 'country',
      required: false,
      placeholder: 'Country',
      value: selectedCountry,
      options: countryList,
      width: '100%',
      isVisible: true,
      disabled: isDisabled('country'),
      onEnter: saveOnEnter,
      onChange: (e) => setSelectedCountry(e),
      hasError: CheckHasError(modalErrors, 'country'),
      errorMessage: GenerateError(modalErrors, 'country'),
    },
  ];

  const notesInput = {
    type: 'textarea',
    label: 'Notes',
    name: 'notes',
    showClear: true,
    id: 'entity',
    value: currentEntity.notes,
    isVisible: true,
    onChange: (e) => updateCurrentEntity(e, true, 'notes'),
  };

  const generateWarningContent = () => {
    return {
      heading: 'Exclude from Household?',
      text: `Excluding an entity from the household will remove them from all financials, reports, etc.<br/><br/><strong>This cannot be undone</strong>.`,
      buttons: [
        {
          text: 'No',
          function: () => setShowRemoveWarning(false),
          theme: ButtonThemes.cancel,
        },
        {
          text: 'Yes',
          theme: ButtonThemes.primary,
          function: () => {
            setShowRemoveWarning(false);
            setCurrentEntity({
              ...currentEntity,
              is_active: !currentEntity.is_active,
            });
          },
        },
      ],
    };
  };

  return (
    <RelationshipDisplayContainer
      isModal={isModal}
      isConsumer={isConsumer}
      name={entityDisplay}
      viewOnly={viewOnly}
      isLoading={!loadedEntity}
      invisible={invisible}
      hide={hide}
      isNew={isNew}
      modalTabOptions={modalTabOptions}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      household={currentHousehold}
      showProposalMode={showProposalMode}
      itemDetails={itemDetails}
      essentialInputs={essentialInputs}
      detailInputs={detailInputs}
      notesInput={notesInput}
      onEnter={saveOnEnter}
      preferences={preferences}
      categories={entityCategories}
      members={members}
      removeContent={{
        showing: showRemoveWarning,
        hide: () => setShowRemoveWarning(!showRemoveWarning),
        content: generateWarningContent(),
      }}
      buttonContent={{
        deleteFunction: openDeleteMember,
        saveFunction: saveEntity,
        showLoading: buttonLoading,
      }}
      modalError={modalError}
      proposalContent={{
        ...member?.proposal_action_data,
        name: member?.name,
        type: 'entity',
      }}
      integrationContent={{
        content: integrationContent,
        loading: loadingIntegrationContent,
        integration: selectedIntegration,
        member: currentEntity,
        error: integrationError,
        role: selectedRole,
        onClick: unlinkMember,
        onClickLoading: isUnlinkingEntity,
        type: 'Entity',
      }}
    />
  );
};

EntityContainer.propTypes = {
  isModal: PropTypes.bool,
  viewOnly: PropTypes.bool,
  hide: PropTypes.func,
  isNew: PropTypes.bool,
  isExcluded: PropTypes.bool,
  householdId: PropTypes.string,
  showProposalMode: PropTypes.bool,
  proposalId: PropTypes.string,
  defaultTab: PropTypes.string,
  invisible: PropTypes.bool,
  isConsumer: PropTypes.bool,
  itemDetails: PropTypes.object,
};

export default EntityContainer;
