/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import InfoBox from '../InfoBox';
import {
  AssetContainer,
  CashFlowContainer,
  // HouseholdDetails,
  InsuranceContainer,
  LiabilityContainer,
  MemberContainer,
  EntityContainer,
} from '../../containers';
import { resetFocusMode } from '../../store/actions';
import {
  //  colors,
  fonts,
} from '../../styles/variables';

const AssetMapModalsSidebar = ({
  householdId,
  itemDetails,
  currency,
  showProposalMode,
  currentProposal,
}) => {
  const dispatch = useDispatch();
  const [hasActiveItem, setHasActiveItem] = useState(false);
  const [type, setType] = useState();
  const [isInstrument, setIsInstrument] = useState(false);
  const [isMember, setIsMember] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetFocusMode());
    };
  }, []);

  useEffect(() => {
    if (itemDetails?.type) {
      setHasActiveItem(true);
      setIsInstrument(itemDetails?.isInstrument);
      setIsMember(itemDetails?.isMember);
      setType(itemDetails?.type);
    } else {
      setHasActiveItem(false);
      setIsInstrument(false);
      setIsMember(false);
      setType();
    }
  }, [itemDetails]);

  return (
    <ModalsSidebarContainer>
      {hasActiveItem ? (
        isInstrument ? (
          type === 'income' ? (
            <CashFlowContainer
              isModal={false}
              isNew={false}
              householdId={householdId}
              currency={currency}
              itemDetails={itemDetails?.item}
              key={itemDetails?.item?.id}
              showProposalMode={showProposalMode}
              proposalId={currentProposal?.id}
            />
          ) : type === 'asset' ? (
            <AssetContainer
              isModal={false}
              isNew={false}
              householdId={householdId}
              currency={currency}
              itemDetails={itemDetails?.item}
              key={itemDetails?.item?.id}
              showProposalMode={showProposalMode}
              proposalId={currentProposal?.id}
            />
          ) : type === 'liability' ? (
            <LiabilityContainer
              isModal={false}
              isNew={false}
              currency={currency}
              householdId={householdId}
              itemDetails={itemDetails?.item}
              key={itemDetails?.item?.id}
              showProposalMode={showProposalMode}
              proposalId={currentProposal?.id}
            />
          ) : type === 'insurance' ? (
            <InsuranceContainer
              isModal={false}
              isNew={false}
              currency={currency}
              householdId={householdId}
              itemDetails={itemDetails?.item}
              key={itemDetails?.item?.id}
              showProposalMode={showProposalMode}
              proposalId={currentProposal?.id}
            />
          ) : (
            <>ERROR NOT FOUND</>
          )
        ) : isMember ? (
          type === 'member' ? (
            <MemberContainer
              isModal={false}
              isNew={false}
              isPersonal={false}
              householdId={householdId}
              itemDetails={itemDetails?.item}
              key={itemDetails?.item?.id}
              showProposalMode={showProposalMode}
              proposalId={currentProposal?.id}
            />
          ) : type === 'entity' ? (
            <EntityContainer
              isModal={false}
              isNew={false}
              householdId={householdId}
              itemDetails={itemDetails?.item}
              key={itemDetails?.item?.id}
              showProposalMode={showProposalMode}
              proposalId={currentProposal?.id}
            />
          ) : (
            <>ERROR NOT FOUND</>
          )
        ) : (
          <div>No supported</div>
        )
      ) : (
        <EmptyInfoContainer>
          <MessageContainer>
            <InfoBox
              message={'Click on a member or financial to see more details.'}
              margin="0"
            />
          </MessageContainer>
          {/* <HouseholdDetailsContainer>
            <HouseholdDetails
              householdId={householdId}
              isConsumer={false}
              isSidebar={true}
            />
          </HouseholdDetailsContainer> */}
        </EmptyInfoContainer>
      )}
    </ModalsSidebarContainer>
  );
};

const ModalsSidebarContainer = styled.div``;

const EmptyInfoContainer = styled.div``;

const MessageContainer = styled.div`
  font-weight: ${fonts.semiBold};
  padding: 0 15px;
`;

// const HouseholdDetailsContainer = styled.div`
//   margin: 25px 0 0 0;
//   background: ${colors.lighterGrey};
// `;

export default AssetMapModalsSidebar;
